var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationObserver", {
    ref: "observer",
    attrs: { tag: "form" },
    on: {
      submit: function ($event) {
        $event.preventDefault()
        return _vm.submit()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ valid }) {
          return [
            !_vm.loading
              ? _c(
                  "div",
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-row",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("b-col", { attrs: { cols: "8" } }, [
                              _c("h4", { staticClass: "mt-2" }, [
                                _vm._v("Editar cadastro"),
                              ]),
                            ]),
                            _c(
                              "b-col",
                              {
                                staticClass: "text-right mt-1",
                                attrs: { cols: "4" },
                              },
                              [
                                _vm.enabledBan
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled: _vm.block_ui,
                                          variant: "dark",
                                        },
                                        on: { click: _vm.banDriver },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "drivers.buttons.banDriver"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.enabledUnban
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled: _vm.block_ui,
                                          variant: "dark",
                                        },
                                        on: { click: _vm.unbanDriver },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "drivers.buttons.unbanDriver"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      disabled:
                                        _vm.block_ui ||
                                        !valid ||
                                        _vm.canNotSaveWhenExpired,
                                      variant: "success",
                                    },
                                    on: { click: _vm.submit },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("drivers.buttons.save")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      disabled: _vm.block_ui,
                                      variant: "danger",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.goBack.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("drivers.buttons.cancel")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form",
                          [
                            _c(
                              "b-form-row",
                              [
                                _vm.$regions.isBrazil()
                                  ? _c(
                                      "b-form-group",
                                      {
                                        staticClass: "col-md-3 col-sm-12",
                                        attrs: {
                                          label: "CPF *",
                                          "label-for": "driver-cpf",
                                        },
                                      },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: "cpfValid",
                                            name: "CPF",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  errors,
                                                  valid,
                                                }) {
                                                  return [
                                                    _c("the-mask", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "driver-cpf",
                                                        type: "text",
                                                        disabled:
                                                          _vm.ruleEditDriver,
                                                        mask: _vm.$t(
                                                          "drivers.masks.document"
                                                        ),
                                                      },
                                                      model: {
                                                        value: _vm.data.cpf,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.data,
                                                            "cpf",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression: "data.cpf",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticClass: "error" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(errors[0]) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.$regions.isMexico()
                                  ? _c(
                                      "b-form-group",
                                      {
                                        staticClass: "col-md-3 col-sm-12",
                                        attrs: {
                                          label: "CURP *",
                                          "label-for": "driver-curp",
                                        },
                                      },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: "validateCurp",
                                            name: "CURP",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  errors,
                                                  valid,
                                                }) {
                                                  return [
                                                    _c("the-mask", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "driver-curp",
                                                        type: "text",
                                                        disabled:
                                                          _vm.ruleEditDriver,
                                                        mask: _vm.$t(
                                                          "drivers.masks.document"
                                                        ),
                                                      },
                                                      model: {
                                                        value: _vm.data.cpf,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.data,
                                                            "cpf",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression: "data.cpf",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticClass: "error" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(errors[0]) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-4 col-sm-12",
                                    attrs: {
                                      label: _vm.$t(
                                        "drivers.labels.driverName"
                                      ),
                                      "label-for": "driver-name",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "driver-name",
                                        type: "text",
                                        disabled: _vm.block_ui,
                                      },
                                      model: {
                                        value: _vm.data.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "name",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "data.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-2 col-sm-12",
                                    attrs: {
                                      label: _vm.$t("drivers.labels.nickName"),
                                      "label-for": "driver-nickname",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "driver-nickname",
                                        type: "text",
                                        disabled: _vm.block_ui,
                                      },
                                      model: {
                                        value: _vm.data.nickname,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "nickname",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "data.nickname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-3 col-sm-12",
                                    attrs: {
                                      label: _vm.$t("drivers.labels.status"),
                                      "label-for": "driver-status",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "driver-status",
                                        options: _vm.statuses,
                                        disabled:
                                          _vm.block_ui || _vm.status_blocked,
                                      },
                                      model: {
                                        value: _vm.data.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "status", $$v)
                                        },
                                        expression: "data.status",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-3 col-sm-12",
                                    attrs: {
                                      label: _vm.$t(
                                        "drivers.labels.birthdayDate"
                                      ),
                                      "label-for": "driver-birthdate",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "driver-birthdate",
                                        disabled: _vm.ruleEditDriver,
                                        type: "date",
                                      },
                                      model: {
                                        value: _vm.data.birthdate,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "birthdate", $$v)
                                        },
                                        expression: "data.birthdate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-4 col-sm-12",
                                    attrs: {
                                      label:
                                        _vm.$t("drivers.labels.email") + " *",
                                      "label-for": "driver-email",
                                    },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        rules: "required|emailValid",
                                        name: "email",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "driver-email",
                                                    autocomplete: "email",
                                                    type: "email",
                                                    disabled: _vm.block_ui,
                                                  },
                                                  model: {
                                                    value: _vm.data.email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.data,
                                                        "email",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression: "data.email",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "error" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(errors[0]) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-3 col-sm-12",
                                    attrs: {
                                      "label-for": "driver-email-verified",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("   "),
                                    ]),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { disabled: _vm.block_ui },
                                        model: {
                                          value: _vm.data.email_verified,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "email_verified",
                                              $$v
                                            )
                                          },
                                          expression: "data.email_verified",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("drivers.texts.verify")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { disabled: _vm.block_ui },
                                        model: {
                                          value: _vm.data.unsubscribed,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "unsubscribed",
                                              $$v
                                            )
                                          },
                                          expression: "data.unsubscribed",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("drivers.texts.news")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-3 col-sm-4",
                                    attrs: {
                                      label:
                                        _vm.$t("drivers.labels.firstPhone") +
                                        " *",
                                      "label-for": "driver-phone-number",
                                    },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        rules: "required|validatePhone",
                                        name: _vm.$t(
                                          "drivers.labels.inputNamePhone"
                                        ),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("the-mask", {
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    id: "driver-phone-number",
                                                    type: "text",
                                                    disabled: _vm.block_ui,
                                                    mask: _vm.$t(
                                                      "drivers.masks.phone"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.data.phone_number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.data,
                                                        "phone_number",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "data.phone_number",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "error" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(errors[0]) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-2 col-sm-4",
                                    attrs: {
                                      "label-for": "driver-phone-verified",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("   "),
                                    ]),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { disabled: _vm.block_ui },
                                        model: {
                                          value: _vm.data.phone_number_verified,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "phone_number_verified",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.phone_number_verified",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("drivers.texts.verify")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                                _vm.$regions.isMexico()
                                  ? _c(
                                      "b-form-group",
                                      {
                                        staticClass: "col-md-3 col-sm-4",
                                        attrs: {
                                          label: _vm.$t("drivers.labels.rfc"),
                                          "label-for": "driver-rfc",
                                        },
                                      },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: "required|validateRfc",
                                            name: _vm.$t("drivers.labels.rfc"),
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  errors,
                                                  valid,
                                                }) {
                                                  return [
                                                    _c("b-form-input", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "driver-rfc",
                                                        type: "text",
                                                        disabled: _vm.block_ui,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.data
                                                            .fiscal_number,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.data,
                                                            "fiscal_number",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.fiscal_number",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticClass: "error" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(errors[0]) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c("b-form-row", [
                              _c(
                                "div",
                                { staticClass: "col-md-6 col-sm-12" },
                                [
                                  _vm.$regions.isBrazil()
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "container-radio",
                                          attrs: {
                                            label:
                                              _vm.emergencyPhoneOneItems.label,
                                          },
                                        },
                                        _vm._l(
                                          _vm.emergencyPhoneOneItems.items,
                                          function (item, key) {
                                            return _c(
                                              "b-form-radio",
                                              {
                                                key: key,
                                                attrs: {
                                                  name: item.name,
                                                  value: item.value,
                                                },
                                                model: {
                                                  value:
                                                    _vm.emergencyPhoneOneItems
                                                      .selectedValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.emergencyPhoneOneItems,
                                                      "selectedValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "emergencyPhoneOneItems.selectedValue",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.label) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-form-row",
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "col-md-6 col-sm-12",
                                          attrs: {
                                            label: _vm.$t(
                                              "drivers.labels.emergencyName"
                                            ),
                                            "label-for":
                                              "driver-emergency-1-name",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "driver-emergency-1-name",
                                              type: "text",
                                              disabled: _vm.block_ui,
                                            },
                                            model: {
                                              value: _vm.data.emergency_1_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "emergency_1_name",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.emergency_1_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "col-md-6 col-sm-10",
                                          attrs: {
                                            label:
                                              _vm.$t(
                                                "drivers.labels.secoundPhone"
                                              ) + " *",
                                            "label-for":
                                              "driver-emergency-1-phone-number",
                                          },
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "required|validatePhone",
                                              name: _vm.$t(
                                                "drivers.labels.inputNamePhone"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    errors,
                                                    valid,
                                                  }) {
                                                    return [
                                                      _c("the-mask", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "driver-emergency-1-phone-number",
                                                          type: "text",
                                                          disabled:
                                                            _vm.block_ui,
                                                          mask:
                                                            _vm
                                                              .emergencyPhoneOneItems
                                                              .selectedValue ==
                                                            0
                                                              ? _vm.$t(
                                                                  "drivers.masks.phone"
                                                                )
                                                              : "+## ## #### ####",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.data
                                                              .emergency_1_phone_number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.data,
                                                              "emergency_1_phone_number",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.emergency_1_phone_number",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 col-sm-12" },
                                [
                                  _vm.$regions.isBrazil()
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "container-radio",
                                          attrs: {
                                            label:
                                              _vm.emergencyPhoneTwoItems.label,
                                          },
                                        },
                                        _vm._l(
                                          _vm.emergencyPhoneTwoItems.items,
                                          function (item, key) {
                                            return _c(
                                              "b-form-radio",
                                              {
                                                key: key,
                                                attrs: {
                                                  name: item.name,
                                                  value: item.value,
                                                },
                                                model: {
                                                  value:
                                                    _vm.emergencyPhoneTwoItems
                                                      .selectedValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.emergencyPhoneTwoItems,
                                                      "selectedValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "emergencyPhoneTwoItems.selectedValue",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.label) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-form-row",
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "col-md-6 col-sm-12",
                                          attrs: {
                                            label: _vm.$t(
                                              "drivers.labels.secoundyEmergencyName"
                                            ),
                                            "label-for":
                                              "driver-emergency-2-name",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "driver-emergency-2-name",
                                              type: "text",
                                              disabled: _vm.block_ui,
                                            },
                                            model: {
                                              value: _vm.data.emergency_2_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "emergency_2_name",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.emergency_2_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "col-md-6 col-sm-10",
                                          attrs: {
                                            label: _vm.$t(
                                              "drivers.labels.thirdPhone"
                                            ),
                                            "label-for":
                                              "driver-emergency-2-phone-number",
                                          },
                                        },
                                        [
                                          _c("the-mask", {
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "driver-emergency-2-phone-number",
                                              type: "text",
                                              disabled: _vm.block_ui,
                                              mask:
                                                _vm.emergencyPhoneTwoItems
                                                  .selectedValue == 0
                                                  ? _vm.$t(
                                                      "drivers.masks.phone"
                                                    )
                                                  : "+## ## #### ####",
                                            },
                                            model: {
                                              value:
                                                _vm.data
                                                  .emergency_2_phone_number,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "emergency_2_phone_number",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.emergency_2_phone_number",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("hr"),
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-2 col-sm-12",
                                    attrs: {
                                      label:
                                        _vm.$t("drivers.labels.licenceNumber") +
                                        " *",
                                      "label-for": "driver-license-number",
                                    },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        rules: "cnhValid",
                                        name: _vm.$t(
                                          "drivers.labels.inputNameCnh"
                                        ),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "driver-license-number",
                                                    type: "text",
                                                    disabled:
                                                      _vm.ruleCnhEditDriver,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.data.license_number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.data,
                                                        "license_number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "data.license_number",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "error" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(errors[0]) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-1 col-sm-12",
                                    attrs: {
                                      label: _vm.$t("drivers.labels.category"),
                                      "label-for": "driver-license-category",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "driver-license-category",
                                        type: "text",
                                      },
                                      model: {
                                        value: _vm.data.license_category,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "license_category",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "data.license_category",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-1 col-sm-12",
                                    attrs: {
                                      label: _vm.$t("drivers.labels.uf"),
                                      "label-for": "driver-license-state",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "driver-license-state",
                                        type: "text",
                                      },
                                      model: {
                                        value: _vm.data.license_state,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "license_state",
                                            $$v
                                          )
                                        },
                                        expression: "data.license_state",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-1 col-sm-12",
                                    attrs: {
                                      label: _vm.$t("drivers.labels.points"),
                                      "label-for": "driver-license-points",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "driver-license-points",
                                        type: "text",
                                      },
                                      model: {
                                        value: _vm.data.license_points,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "license_points",
                                            $$v
                                          )
                                        },
                                        expression: "data.license_points",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-md-2 col-sm-12",
                                    attrs: {
                                      label: _vm.$t(
                                        "drivers.labels.issuanceDate"
                                      ),
                                      "label-for": "driver-license-issue-date",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      staticClass: "driver-license-issue-date",
                                      attrs: {
                                        id: "driver-license-issue-date",
                                        type: "date",
                                      },
                                      model: {
                                        value: _vm.data.license_issued_at,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "license_issued_at",
                                            $$v
                                          )
                                        },
                                        expression: "data.license_issued_at",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.$regions.isBrazil()
                                  ? _c(
                                      "b-form-group",
                                      {
                                        staticClass: "col-md-2 col-sm-12",
                                        attrs: {
                                          label: _vm.$t(
                                            "drivers.labels.dueDate"
                                          ),
                                          "label-for":
                                            "driver-license-expire-date",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "driver-license-expire-date",
                                          attrs: {
                                            id: "driver-license-expire-date",
                                            type: "date",
                                            min: _vm.minDate,
                                            state: !_vm.canNotSaveWhenExpired,
                                          },
                                          model: {
                                            value: _vm.data.license_expired_at,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.data,
                                                "license_expired_at",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.license_expired_at",
                                          },
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: {
                                              id: "driver-license-expire-date-feedback",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "drivers.texts.driverDocumentExpired"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.$regions.isBrazil()
                                  ? _c(
                                      "b-form-group",
                                      {
                                        staticClass: "col-md-3 col-sm-12",
                                        attrs: {
                                          label: _vm.$t(
                                            "drivers.labels.situation"
                                          ),
                                          "label-for": "driver-license-status",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            attrs: {
                                              id: "dropdown-buttons",
                                              text: _vm.newLicenseStatus.text,
                                              disabled: _vm.ruleEditDriver,
                                            },
                                          },
                                          _vm._l(
                                            _vm.licenseStatusEnum,
                                            function (licenseStatus, index) {
                                              return _c(
                                                "b-dropdown-item-button",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    disabled:
                                                      !licenseStatus.enable,
                                                    value: licenseStatus.value,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeLicenseStatus(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        licenseStatus.text
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("hr"),
                            _c("kovi-address", {
                              attrs: {
                                "google-input": _vm.configAddress.googleInput,
                                "address-name": _vm.configAddress.addressName,
                                "address-number":
                                  _vm.configAddress.addressNumber,
                                "address-no-number":
                                  _vm.configAddress.addressNoNumber,
                                "address-complement":
                                  _vm.configAddress.addressComplement,
                                "address-neighborhood":
                                  _vm.configAddress.addressNeighborhood,
                                "address-city": _vm.configAddress.addressCity,
                                "address-state": _vm.configAddress.addressState,
                                "address-country":
                                  _vm.configAddress.addressCountry,
                                "address-postal-code":
                                  _vm.configAddress.addressPostalCode,
                                "address-lat-lng":
                                  _vm.configAddress.addressLatLng,
                                "garage-address-name":
                                  _vm.configAddress.garageAddressName,
                                "garage-address-number":
                                  _vm.configAddress.garageAddressNumber,
                                "garage-address-complement":
                                  _vm.configAddress.garageAddressComplement,
                                "garage-address-neighborhood":
                                  _vm.configAddress.garageAddressNeighborhood,
                                "garage-address-city":
                                  _vm.configAddress.garageAddressCity,
                                "garage-address-state":
                                  _vm.configAddress.garageAddressState,
                                "garage-address-country":
                                  _vm.configAddress.garageAddressCountry,
                                "garage-address-postal-code":
                                  _vm.configAddress.garageAddressPostalCode,
                                "message-required-address-fields":
                                  _vm.configAddress
                                    .messageRequiredAddressFields,
                                "message-required-garage-fields":
                                  _vm.configAddress.messageRequiredGarageFields,
                                "message-invalid-address":
                                  _vm.configAddress.messageInvalidAddress,
                                "show-garage-address": true,
                              },
                              on: {
                                "new-address": function ($event) {
                                  return _vm.updateAddressModel($event)
                                },
                              },
                            }),
                            _c(
                              "b-row",
                              { attrs: { slot: "footer" }, slot: "footer" },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled:
                                            _vm.block_ui ||
                                            !valid ||
                                            !_vm.validAddress ||
                                            _vm.canNotSaveWhenExpired,
                                          variant: "success",
                                        },
                                        on: { click: _vm.submit },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("drivers.buttons.save")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          disabled: _vm.block_ui,
                                          variant: "danger",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.goBack.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("drivers.buttons.cancel")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "animated fadeIn" },
                  [_c("content-loading")],
                  1
                ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }