<template>
  <ValidationObserver v-slot="{ valid }" ref="observer" tag="form" @submit.prevent="submit()">
    <div v-if="!loading">
      <b-card>
        <b-row slot="header">
          <b-col cols="8">
            <h4 class="mt-2">Editar cadastro</h4>
          </b-col>

          <b-col cols="4" class="text-right mt-1">
            <b-button v-if="enabledBan" :disabled="block_ui" class="mr-2" variant="dark" @click="banDriver">
              {{ $t('drivers.buttons.banDriver') }}
            </b-button>

            <b-button v-if="enabledUnban" :disabled="block_ui" class="mr-2" variant="dark" @click="unbanDriver">
              {{ $t('drivers.buttons.unbanDriver') }}
            </b-button>

            <b-button :disabled="block_ui || !valid || canNotSaveWhenExpired" class="mr-2" variant="success" @click="submit">
              {{ $t('drivers.buttons.save') }}
            </b-button>

            <b-button :disabled="block_ui" variant="danger" @click.prevent="goBack">
              {{ $t('drivers.buttons.cancel') }}
            </b-button>
          </b-col>
        </b-row>

        <b-form>
          <b-form-row>
            <b-form-group v-if="$regions.isBrazil()" label="CPF *" class="col-md-3 col-sm-12" label-for="driver-cpf">
              <ValidationProvider v-slot="{ errors, valid }" :rules="'cpfValid'" name="CPF">
                <the-mask
                  id="driver-cpf"
                  v-model.trim="data.cpf"
                  type="text"
                  class="form-control"
                  :disabled="ruleEditDriver"
                  :mask="$t('drivers.masks.document')"
                />
                <span class="error">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </b-form-group>

            <b-form-group v-if="$regions.isMexico()" label="CURP *" class="col-md-3 col-sm-12" label-for="driver-curp">
              <ValidationProvider v-slot="{ errors, valid }" :rules="'validateCurp'" name="CURP">
                <the-mask
                  id="driver-curp"
                  v-model.trim="data.cpf"
                  type="text"
                  class="form-control"
                  :disabled="ruleEditDriver"
                  :mask="$t('drivers.masks.document')"
                />
                <span class="error">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </b-form-group>

            <b-form-group :label="$t('drivers.labels.driverName')" label-for="driver-name" class="col-md-4 col-sm-12">
              <b-form-input id="driver-name" v-model.trim="data.name" type="text" :disabled="block_ui" />
            </b-form-group>

            <b-form-group :label="$t('drivers.labels.nickName')" label-for="driver-nickname" class="col-md-2 col-sm-12">
              <b-form-input id="driver-nickname" v-model.trim="data.nickname" type="text" :disabled="block_ui" />
            </b-form-group>

            <b-form-group :label="$t('drivers.labels.status')" label-for="driver-status" class="col-md-3 col-sm-12">
              <b-form-select id="driver-status" v-model="data.status" :options="statuses" :disabled="block_ui || status_blocked" />
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group :label="$t('drivers.labels.birthdayDate')" label-for="driver-birthdate" class="col-md-3 col-sm-12">
              <b-form-input id="driver-birthdate" v-model="data.birthdate" :disabled="ruleEditDriver" type="date" />
            </b-form-group>

            <b-form-group :label="$t('drivers.labels.email') + ' *'" label-for="driver-email" class="col-md-4 col-sm-12">
              <ValidationProvider v-slot="{ errors, valid }" :rules="'required|emailValid'" name="email">
                <b-form-input id="driver-email" v-model.trim="data.email" autocomplete="email" type="email" :disabled="block_ui" />
                <span class="error">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </b-form-group>

            <b-form-group label-for="driver-email-verified" class="col-md-3 col-sm-12">
              <template slot="label"> &nbsp; </template>
              <b-form-checkbox v-model="data.email_verified" :disabled="block_ui" class="mt-1">
                {{ $t('drivers.texts.verify') }}
              </b-form-checkbox>
              <b-form-checkbox v-model="data.unsubscribed" :disabled="block_ui" class="mt-1">
                {{ $t('drivers.texts.news') }}
              </b-form-checkbox>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group :label="$t('drivers.labels.firstPhone') + ' *'" label-for="driver-phone-number" class="col-md-3 col-sm-4">
              <ValidationProvider v-slot="{ errors, valid }" :rules="'required|validatePhone'" :name="$t('drivers.labels.inputNamePhone')">
                <the-mask
                  id="driver-phone-number"
                  v-model.trim="data.phone_number"
                  type="text"
                  class="form-control"
                  :disabled="block_ui"
                  :mask="$t('drivers.masks.phone')"
                />
                <span class="error">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </b-form-group>

            <b-form-group label-for="driver-phone-verified" class="col-md-2 col-sm-4">
              <template slot="label"> &nbsp; </template>
              <b-form-checkbox v-model="data.phone_number_verified" class="mt-1" :disabled="block_ui">
                {{ $t('drivers.texts.verify') }}
              </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="$regions.isMexico()" :label="$t('drivers.labels.rfc')" label-for="driver-rfc" class="col-md-3 col-sm-4">
              <ValidationProvider v-slot="{ errors, valid }" :rules="'required|validateRfc'" :name="$t('drivers.labels.rfc')">
                <b-form-input id="driver-rfc" v-model.trim="data.fiscal_number" type="text" class="form-control" :disabled="block_ui" />
                <span class="error">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </b-form-group>
          </b-form-row>

          <hr />

          <b-form-row>
            <div class="col-md-6 col-sm-12">
              <b-form-group v-if="$regions.isBrazil()" class="container-radio" :label="emergencyPhoneOneItems.label">
                <b-form-radio
                  v-for="(item, key) in emergencyPhoneOneItems.items"
                  :key="key"
                  v-model="emergencyPhoneOneItems.selectedValue"
                  :name="item.name"
                  :value="item.value"
                >
                  {{ item.label }}
                </b-form-radio>
              </b-form-group>

              <b-form-row>
                <b-form-group :label="$t('drivers.labels.emergencyName')" label-for="driver-emergency-1-name" class="col-md-6 col-sm-12">
                  <b-form-input id="driver-emergency-1-name" v-model.trim="data.emergency_1_name" type="text" :disabled="block_ui" />
                </b-form-group>

                <b-form-group :label="$t('drivers.labels.secoundPhone') + ' *'" label-for="driver-emergency-1-phone-number" class="col-md-6 col-sm-10">
                  <ValidationProvider v-slot="{ errors, valid }" :rules="'required|validatePhone'" :name="$t('drivers.labels.inputNamePhone')">
                    <the-mask
                      id="driver-emergency-1-phone-number"
                      v-model.trim="data.emergency_1_phone_number"
                      type="text"
                      class="form-control"
                      :disabled="block_ui"
                      :mask="emergencyPhoneOneItems.selectedValue == 0 ? $t('drivers.masks.phone') : '+## ## #### ####'"
                    />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-form-row>
            </div>

            <div class="col-md-6 col-sm-12">
              <b-form-group v-if="$regions.isBrazil()" :label="emergencyPhoneTwoItems.label" class="container-radio">
                <b-form-radio
                  v-for="(item, key) in emergencyPhoneTwoItems.items"
                  :key="key"
                  v-model="emergencyPhoneTwoItems.selectedValue"
                  :name="item.name"
                  :value="item.value"
                >
                  {{ item.label }}
                </b-form-radio>
              </b-form-group>

              <b-form-row>
                <b-form-group :label="$t('drivers.labels.secoundyEmergencyName')" label-for="driver-emergency-2-name" class="col-md-6 col-sm-12">
                  <b-form-input id="driver-emergency-2-name" v-model.trim="data.emergency_2_name" type="text" :disabled="block_ui" />
                </b-form-group>

                <b-form-group :label="$t('drivers.labels.thirdPhone')" label-for="driver-emergency-2-phone-number" class="col-md-6 col-sm-10">
                  <the-mask
                    id="driver-emergency-2-phone-number"
                    v-model.trim="data.emergency_2_phone_number"
                    type="text"
                    class="form-control"
                    :disabled="block_ui"
                    :mask="emergencyPhoneTwoItems.selectedValue == 0 ? $t('drivers.masks.phone') : '+## ## #### ####'"
                  />
                </b-form-group>
              </b-form-row>
            </div>
          </b-form-row>

          <hr />

          <b-form-row>
            <b-form-group :label="$t('drivers.labels.licenceNumber') + ' *'" label-for="driver-license-number" class="col-md-2 col-sm-12">
              <ValidationProvider v-slot="{ errors, valid }" :rules="'cnhValid'" :name="$t('drivers.labels.inputNameCnh')">
                <b-form-input id="driver-license-number" v-model="data.license_number" type="text" :disabled="ruleCnhEditDriver" />
                <span class="error">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group :label="$t('drivers.labels.category')" label-for="driver-license-category" class="col-md-1 col-sm-12">
              <b-form-input id="driver-license-category" v-model.trim="data.license_category" type="text" />
            </b-form-group>
            <b-form-group :label="$t('drivers.labels.uf')" label-for="driver-license-state" class="col-md-1 col-sm-12">
              <b-form-input id="driver-license-state" v-model="data.license_state" type="text" />
            </b-form-group>
            <b-form-group :label="$t('drivers.labels.points')" label-for="driver-license-points" class="col-md-1 col-sm-12">
              <b-form-input id="driver-license-points" v-model="data.license_points" type="text" />
            </b-form-group>
            <b-form-group :label="$t('drivers.labels.issuanceDate')" label-for="driver-license-issue-date" class="col-md-2 col-sm-12">
              <b-form-input id="driver-license-issue-date" v-model="data.license_issued_at" class="driver-license-issue-date" type="date" />
            </b-form-group>
            <b-form-group v-if="$regions.isBrazil()" :label="$t('drivers.labels.dueDate')" label-for="driver-license-expire-date" class="col-md-2 col-sm-12">
              <b-form-input
                id="driver-license-expire-date"
                v-model="data.license_expired_at"
                class="driver-license-expire-date"
                type="date"
                :min="minDate"
                :state="!canNotSaveWhenExpired"
              />
              <b-form-invalid-feedback id="driver-license-expire-date-feedback">
                {{ $t('drivers.texts.driverDocumentExpired') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group v-if="$regions.isBrazil()" :label="$t('drivers.labels.situation')" label-for="driver-license-status" class="col-md-3 col-sm-12">
              <b-dropdown id="dropdown-buttons" :text="newLicenseStatus.text" :disabled="ruleEditDriver">
                <b-dropdown-item-button
                  v-for="(licenseStatus, index) in licenseStatusEnum"
                  :key="index"
                  :disabled="!licenseStatus.enable"
                  :value="licenseStatus.value"
                  @click="changeLicenseStatus(index)"
                >
                  {{ licenseStatus.text }}
                </b-dropdown-item-button>
              </b-dropdown>
            </b-form-group>
          </b-form-row>

          <hr />

          <kovi-address
            :google-input="configAddress.googleInput"
            :address-name="configAddress.addressName"
            :address-number="configAddress.addressNumber"
            :address-no-number="configAddress.addressNoNumber"
            :address-complement="configAddress.addressComplement"
            :address-neighborhood="configAddress.addressNeighborhood"
            :address-city="configAddress.addressCity"
            :address-state="configAddress.addressState"
            :address-country="configAddress.addressCountry"
            :address-postal-code="configAddress.addressPostalCode"
            :address-lat-lng="configAddress.addressLatLng"
            :garage-address-name="configAddress.garageAddressName"
            :garage-address-number="configAddress.garageAddressNumber"
            :garage-address-complement="configAddress.garageAddressComplement"
            :garage-address-neighborhood="configAddress.garageAddressNeighborhood"
            :garage-address-city="configAddress.garageAddressCity"
            :garage-address-state="configAddress.garageAddressState"
            :garage-address-country="configAddress.garageAddressCountry"
            :garage-address-postal-code="configAddress.garageAddressPostalCode"
            :message-required-address-fields="configAddress.messageRequiredAddressFields"
            :message-required-garage-fields="configAddress.messageRequiredGarageFields"
            :message-invalid-address="configAddress.messageInvalidAddress"
            :show-garage-address="true"
            @new-address="updateAddressModel($event)"
          />

          <b-row slot="footer">
            <b-col class="text-right">
              <b-button :disabled="block_ui || !valid || !validAddress || canNotSaveWhenExpired" class="mr-2" variant="success" @click="submit">
                {{ $t('drivers.buttons.save') }}
              </b-button>

              <b-button :disabled="block_ui" variant="danger" @click.prevent="goBack">
                {{ $t('drivers.buttons.cancel') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </div>

    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </ValidationObserver>
</template>

<script>
import ContentLoading from '@components/content-loading';
import KoviAddress from '@components/custom/address/kovi-address';
import { enumStatus, enumLicenseStatus } from '@utils/driver';
import { driverErrors } from '@utils/graphql-messages';
import { makeQuery } from '@graphql/middleware';
import DRIVER_GET from '@graphql/driver/queries/get.gql';
import DRIVER_UPDATE from '@graphql/driver/mutations/update-profile.gql';
import DRIVER_BAN from '@graphql/driver/mutations/ban-driver.gql';
import DRIVER_UNBAN from '@graphql/driver/mutations/unban-driver.gql';
import { isMexico } from '@utils/helper-region';
import { mapGetters } from 'vuex';
import { isAfter, isEqual } from 'date-fns';

export default {
  name: 'DriverProfileEdit',
  page: {
    title: 'Motoristas',
  },
  components: {
    ContentLoading,
    KoviAddress,
  },
  data() {
    return {
      loading: true,
      data: {
        name: '',
      },
      validAddress: false,
      garage_address: {
        postal_code: '',
        street_name: '',
        street_number: '',
        street_details: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      emergencyPhoneOneItems: {
        label: 'O telefone de emergência 1 é fixo ou celular?',
        selectedValue: 0,
        items: [
          {
            value: 0,
            name: 'emergency-celular-one',
            label: 'Telefone Celular',
          },
          {
            value: 1,
            name: 'emergency-fixo-one',
            label: 'Telefone Fixo',
          },
        ],
      },
      emergencyPhoneTwoItems: {
        label: 'O telefone de emergência 2 é fixo ou celular?',
        selectedValue: 0,
        items: [
          {
            value: 0,
            name: 'emergency-celular-two',
            label: 'Telefone Celular',
          },
          {
            value: 1,
            name: 'emergency-fixo-two',
            label: 'Telefone Fixo',
          },
        ],
      },
      password_status: false,
      form_status_options: enumStatus,
      block_ui: false,
      maskLetterNumberUpper: {
        F: {
          pattern: /[a-zA-Z0-9]/,
          transform: v => v.toLocaleUpperCase(),
        },
      },
      newLicenseStatus: null,
      minDate: this.calculateMinDate(),
    };
  },
  computed: {
    ...mapGetters({
      country: 'user/country',
    }),
    configAddress() {
      let countryListOptions = [{ text: 'Brasil', value: 'BR' }];
      if (isMexico()) countryListOptions = [{ text: 'México', value: 'MX' }];

      const {
        address_street_name,
        address_street_number,
        address_street_no_number,
        address_street_details,
        address_neighborhood,
        address_city,
        address_state,
        address_postal_code,
        address_latlong,
      } = this.data;

      const {
        street_name: g_street_namestreet_name,
        street_number: g_street_number,
        street_details: g_street_details,
        neighborhood: g_neighborhood,
        city: g_city,
        state: g_state,
        postal_code: g_postal_code,
      } = this.data.garage_address;

      const validPostalCodeLength = isMexico() ? 5 : 8;
      const newNumber = !address_street_number || address_street_number === 'N/A' ? '' : address_street_number;
      const noNumber = !newNumber || address_street_no_number ? true : false;

      return {
        googleInput: {
          color: '000000',
          borderWidth: '1px',
          borderColor: '228, 231, 234',
          borderRadius: '0.25rem',
          textAlign: 'center',
          complete: 'off',
          paddingTop: '8',
          paddingLeft: '10',
          paddingRight: '20',
          paddingBottom: '8',
          locale: this.country,
          placeholder: this.$t('drivers.components.koviAddress.google.placeholder'),
          title: this.$t('drivers.components.koviAddress.texts.addressTitle'),
          garageCheckTitle: this.$t('drivers.components.koviAddress.texts.garageAddressCheck'),
          garageBlockTitle: this.$t('drivers.components.koviAddress.texts.garageBlockTitle'),
          garageTitle: this.$t('drivers.components.koviAddress.texts.garageTitle'),
        },
        addressName: {
          required: true,
          editable: false,
          title: this.$t('drivers.labels.publicPlace'),
          placeholder: '',
          showPlaceholder: false,
          value: address_street_name,
          type: 'text',
        },
        addressNumber: {
          required: true,
          editable: true,
          title: this.$t('drivers.labels.number'),
          placeholder: 'Número',
          showPlaceholder: false,
          value: newNumber,
          type: 'text',
          mask: '#####',
        },
        addressNoNumber: {
          required: false,
          editable: true,
          title: this.$t('drivers.texts.noNumber'),
          value: noNumber,
        },
        addressComplement: {
          required: false,
          editable: true,
          title: this.$t('drivers.labels.complement'),
          placeholder: '',
          showPlaceholder: false,
          value: address_street_details,
          type: 'text',
        },
        addressNeighborhood: {
          required: true,
          editable: true,
          title: this.$t('drivers.labels.district'),
          placeholder: '',
          showPlaceholder: false,
          value: address_neighborhood,
          type: 'text',
        },
        addressCity: {
          required: false,
          editable: false,
          title: this.$t('drivers.labels.city'),
          placeholder: '',
          showPlaceholder: false,
          value: address_city,
          type: 'text',
        },
        addressState: {
          required: false,
          editable: false,
          title: this.$t('drivers.labels.state'),
          placeholder: '',
          showPlaceholder: false,
          value: address_state,
          type: 'text',
        },
        addressCountry: {
          required: false,
          editable: false,
          title: this.$t('drivers.labels.country'),
          placeholder: '',
          showPlaceholder: false,
          options: countryListOptions,
          value: countryListOptions[0].value,
          type: 'text',
        },
        addressPostalCode: {
          required: true,
          editable: true,
          title: this.$t('drivers.labels.cep'),
          placeholder: '',
          showPlaceholder: false,
          value: address_postal_code,
          type: 'text',
          mask: this.$t('drivers.masks.postalCode'),
          validLength: validPostalCodeLength,
        },
        addressLatLng: {
          required: true,
          value: address_latlong,
          type: 'text',
        },
        garageAddressName: {
          required: true,
          editable: false,
          title: this.$t('drivers.labels.publicPlace'),
          placeholder: '',
          showPlaceholder: false,
          value: g_street_namestreet_name,
          type: 'text',
        },
        garageAddressNumber: {
          required: false,
          editable: true,
          title: this.$t('drivers.labels.number'),
          placeholder: 'Número',
          showPlaceholder: false,
          value: g_street_number,
          type: 'text',
          mask: '#####',
        },
        garageAddressComplement: {
          required: false,
          editable: true,
          title: this.$t('drivers.labels.complement'),
          placeholder: '',
          showPlaceholder: false,
          value: g_street_details,
          type: 'text',
        },
        garageAddressNeighborhood: {
          required: true,
          editable: false,
          title: this.$t('drivers.labels.district'),
          placeholder: '',
          showPlaceholder: false,
          value: g_neighborhood,
          type: 'text',
        },
        garageAddressCity: {
          required: true,
          editable: false,
          title: this.$t('drivers.labels.city'),
          placeholder: '',
          showPlaceholder: false,
          value: g_city,
          type: 'text',
        },
        garageAddressState: {
          required: true,
          editable: false,
          title: this.$t('drivers.labels.state'),
          placeholder: '',
          showPlaceholder: false,
          value: g_state,
          type: 'text',
        },
        garageAddressCountry: {
          required: true,
          editable: false,
          title: this.$t('drivers.labels.country'),
          placeholder: '',
          showPlaceholder: false,
          options: countryListOptions,
          value: countryListOptions[0].value,
          type: 'text',
        },
        garageAddressPostalCode: {
          required: true,
          editable: true,
          title: this.$t('drivers.labels.cep'),
          placeholder: '',
          showPlaceholder: false,
          value: g_postal_code,
          type: 'text',
          mask: this.$t('drivers.masks.postalCode'),
          validLength: validPostalCodeLength,
        },
        messageRequiredAddressFields: this.$t('drivers.components.koviAddress.errors.messageRequiredAddressFields'),
        messageRequiredGarageFields: this.$t('drivers.components.koviAddress.errors.messageRequiredGarageFields'),
        messageInvalidAddress: this.$t('drivers.components.koviAddress.errors.messageInvalidAddress'),
      };
    },
    status_blocked() {
      return this.data.status !== 'ACTIVE' || this.data.status !== 'BANNED';
    },
    statuses() {
      if (this.status_blocked) {
        return enumStatus.filter(item => ['ACTIVE', 'BANNED'].includes(item.value));
      } else {
        return enumStatus;
      }
    },
    enabledBan() {
      return this.$store.getters['user/groups'].includes('ACL:BUTTON:BANNED:DRIVER') && this.data.status !== 'BANNED';
    },
    enabledUnban() {
      return this.$store.getters['user/groups'].includes('ACL:BUTTON:BANNED:DRIVER') && this.data.status === 'BANNED';
    },
    ruleCnhEditDriver() {
      if (!this.$regions.isBrazil()) {
        return false;
      } else {
        const profile = this.$store.getters['driver/header'];
        const group = this.$store.getters['user/groups'].includes('RULE:EDIT:DRIVER');
        const step = ['LEAD', 'PROSPECT', 'APPS_PENDING', 'DOCS_PENDING', 'DOCS_CHECKING', 'DOCS_REJECTED', 'NO_OPERATION', 'BGC_REJECTED'];

        if (step.includes(profile.step)) return false;

        return !group;
      }
    },
    ruleEditDriver() {
      const profile = this.$store.getters['driver/header'];
      const group = this.$store.getters['user/groups'].includes('RULE:EDIT:DRIVER');
      const step = ['LEAD', 'PROSPECT', 'APPS_PENDING', 'DOCS_PENDING', 'BGC_REJECTED', 'DOCS_REJECTED', 'NO_OPERATION', 'WAITING_LIST'];

      if (step.includes(profile.step)) return false;

      return !group;
    },
    licenseStatusEnum() {
      return enumLicenseStatus;
    },
    canNotSaveWhenExpired() {
      if (isMexico()) {
        return false;
      }
      const today = new Date();
      const pastDate = today.setDate(today.getDate() - 31);
      return isAfter(pastDate, this.data.license_expired_at);
    },
  },
  mounted() {
    this.getDriver();
  },
  methods: {
    getDriver() {
      this.loading = true;
      makeQuery(DRIVER_GET, {
        id: this.$route.params.id,
      })
        .then(({ data }) => {
          const newNumber = !data.driver.address_street_number || data.driver.address_street_number === 'N/A' ? '' : data.driver.address_street_number;
          this.data = {
            ...data.driver,
            address_street_number: newNumber,
            garage_address: data.driver.garage_other_address ? data.driver.garage_address : this.garage_address,
          };

          let currentLicenseStatus = data.driver.license_status;
          if (currentLicenseStatus === 'CHECKING') currentLicenseStatus = null;
          if (currentLicenseStatus === 'SGD_FAILED' || currentLicenseStatus === 'SGD_INVALID') currentLicenseStatus = 'SGD_FAILED_AND_INVALID';
          this.newLicenseStatus = this.licenseStatusEnum.find(x => x.value === currentLicenseStatus);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeLicenseStatus(index) {
      const clickLicenseStatus = this.licenseStatusEnum[index];

      this.newLicenseStatus = {
        value: clickLicenseStatus.value,
        text: clickLicenseStatus.text,
      };
    },
    banDriver() {
      this.block_ui = true;

      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('drivers.texts.areYouSure'),
        text: this.$t('drivers.texts.banDriver'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('drivers.buttons.confirm'),
        cancelButtonText: this.$t('drivers.buttons.cancel'),
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.$apollo
            .mutate({
              mutation: DRIVER_BAN,
              variables: {
                driverId: this.data.id,
              },
            })
            .then(() => {
              confirmationWindow({
                type: 'success',
                title: this.$t('drivers.texts.updateSuccess'),
                showConfirmButton: false,
                timer: 2500,
              }).then(() => {
                this.$router.push({
                  path: '/drivers/profile/' + this.data.id,
                });
              });
            })
            .catch(error => {
              const errorMsg = driverErrors(error.message);

              this.$swal({
                type: 'error',
                title: this.$t('drivers.texts.errorSave'),
                text: errorMsg,
                showConfirmButton: false,
                timer: 2500,
              }).then(() => {
                this.block_ui = false;
              });
            });
        } else {
          this.block_ui = false;
        }
      });
    },
    unbanDriver() {
      this.block_ui = true;

      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('drivers.texts.areYouSure'),
        text: this.$t('drivers.texts.unbanDriver'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('drivers.buttons.confirm'),
        cancelButtonText: this.$t('drivers.buttons.cancel'),
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.$apollo
            .mutate({
              mutation: DRIVER_UNBAN,
              variables: {
                driverId: this.data.id,
              },
            })
            .then(() => {
              confirmationWindow({
                type: 'success',
                title: this.$t('drivers.texts.updateSuccess'),
                showConfirmButton: false,
                timer: 2500,
              }).then(() => {
                this.$router.push({
                  path: '/drivers/profile/' + this.data.id,
                });
              });
            })
            .catch(error => {
              const errorMsg = driverErrors(error.message);

              this.$swal({
                type: 'error',
                title: this.$t('drivers.texts.errorSave'),
                text: errorMsg,
                showConfirmButton: false,
                timer: 2500,
              }).then(() => {
                this.block_ui = false;
              });
            });
        } else {
          this.block_ui = false;
        }
      });
    },
    goBack() {
      this.$router.push(`/drivers/profile/${this.$route.params.id}`);
    },
    checkPassword() {
      if (this.data.password_repeat !== '' && this.data.password_current !== this.data.password_repeat) {
        this.password_status = this.$t('drivers.labels.wrongPassword');
      } else {
        this.password_status = false;
      }
    },
    updateAddressModel($event) {
      const { address, addressIsValid, garage, garageIsValid, hasGarage } = $event;

      this.validAddress = !!(addressIsValid && garageIsValid);

      this.data.address_street_name = address.name;
      this.data.address_street_number = address.number;
      this.data.address_street_no_number = !address.number;
      this.data.address_street_details = address.complement;
      this.data.address_neighborhood = address.neighborhood;
      this.data.address_city = address.city;
      this.data.address_state = address.state;
      this.data.address_postal_code = address.postalCode;
      this.data.address_country = address.country;
      this.data.address_latlong = address.latLng;

      this.data.garage_other_address = hasGarage;
      this.data.garage_address.street_name = garage.name;
      this.data.garage_address.street_number = garage.number;
      this.data.garage_address.street_details = garage.complement;
      this.data.garage_address.neighborhood = garage.neighborhood;
      this.data.garage_address.city = garage.city;
      this.data.garage_address.state = garage.state;
      this.data.garage_address.postal_code = garage.postalCode;
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.block_ui = true;
        const original_data = this.data;
        this.data.finance_background_score = Number(this.data.finance_background_score);

        const confirmationWindow = this.$swal.mixin({
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger mr-3',
          buttonsStyling: false,
        });

        this.data.license_status = this.newLicenseStatus.value;

        if (!this.data.license_number) this.data.license_number = '';

        this.data.license_points = Number(this.data.license_points);

        confirmationWindow({
          title: this.$t('drivers.texts.areYouSure'),
          text: this.$t('drivers.texts.updateDriver'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('drivers.buttons.confirm'),
          cancelButtonText: this.$t('drivers.buttons.cancel'),
          reverseButtons: true,
        }).then(result => {
          if (result.value) {
            this.$apollo
              .mutate({
                // Query
                mutation: DRIVER_UPDATE,
                variables: {
                  ...this.data,
                  address_street_number: this.data.address_street_no_number ? 'N/A' : this.data.address_street_number,
                  garage_address: this.data.garage_other_address ? this.data.garage_address : null,
                },
              })
              .then(() => {
                confirmationWindow({
                  type: 'success',
                  title: this.$t('drivers.texts.updateSuccess'),
                  showConfirmButton: false,
                  timer: 2500,
                }).then(() => {
                  this.$router.push({
                    path: '/drivers/profile/' + this.data.id,
                  });
                });
              })
              .catch(error => {
                const errorMsg = driverErrors(error.message);

                this.$swal({
                  type: 'error',
                  title: this.$t('drivers.texts.errorSave'),
                  text: errorMsg,
                  showConfirmButton: false,
                  timer: 2500,
                }).then(() => {
                  this.block_ui = false;
                  this.data = original_data;
                });
              });
          } else {
            this.block_ui = false;
            this.data = original_data;
          }
        });
      }
    },
    calculateMinDate() {
      const today = new Date();
      const pastDate = new Date(today.setDate(today.getDate() - 30));
      return pastDate.toISOString().split('T')[0];
    },
  },
};
</script>

<style scoped>
#driver-license-expire-date.form-control,
#driver-license-issue-date.form-control {
  padding-right: 0 !important;
}

.error {
  color: #f86c6b;
  position: relative;
  top: 5px;
}

.container-radio >>> div {
  display: flex;
}

.container-radio >>> div:nth-child(2) {
  left: 15px;
}

.form-control.is-invalid {
  background-image: none !important;
}

.form-control.is-valid {
  background-image: none !important;
}
</style>
