var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kovi-address" }, [
    _c(
      "div",
      { staticClass: "kovi-address__input mb-3" },
      [
        _c("p", { staticClass: "kovi-address__input-title" }, [
          _vm._v(" " + _vm._s(_vm.googleInput.title) + " "),
        ]),
        _c("google-address-autocomplete", {
          attrs: {
            id: "kovi-address-autocomplete-address",
            clear: false,
            color: _vm.googleInput.color,
            "border-width": _vm.googleInput.borderWidth,
            "border-color": _vm.googleInput.borderColor,
            "border-radius": _vm.googleInput.borderRadius,
            "text-align": _vm.googleInput.textAlign,
            complete: _vm.googleInput.complete,
            "padding-top": _vm.googleInput.paddingTop,
            "padding-left": _vm.googleInput.paddingLeft,
            "padding-right": _vm.googleInput.paddingRight,
            "padding-bottom": _vm.googleInput.paddingBottom,
            locale: _vm.googleInput.locale,
            placeholder: _vm.googleInput.placeholder,
          },
          on: {
            changed: function ($event) {
              return _vm.changeAddress($event, "address")
            },
            "selected-value": function ($event) {
              return _vm.changeAddress($event, "address")
            },
            focus: _vm.autocompleteFocus,
            blur: _vm.autocompleteBlur,
          },
        }),
      ],
      1
    ),
    _vm.hasAddress
      ? _c(
          "div",
          [
            _c(
              "b-form-row",
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-2 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-country",
                      label: _vm.addressCountry.title,
                      "label-for": "driver-address-country",
                    },
                  },
                  [
                    _c("b-form-select", {
                      ref: "country",
                      attrs: {
                        disabled: !_vm.addressCountry.editable || _vm.searching,
                        value: _vm.addressCountry.value,
                        options: _vm.addressCountry.options,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.updateAddressModel("country", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("mask-input", {
                  ref: "postal_code",
                  attrs: {
                    "mask-label": _vm.addressPostalCode.title,
                    "parent-class": "col-md-2 col-sm-12 mask-style",
                    disabled: !_vm.addressPostalCode.editable || _vm.searching,
                    mask: _vm.addressPostalCode.mask,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.updateAddressModel("postal_code", $event)
                    },
                  },
                  model: {
                    value: _vm.address.postalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.address, "postalCode", $$v)
                    },
                    expression: "address.postalCode",
                  },
                }),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-5 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-street",
                      label: _vm.addressName.title,
                      "label-for": "driver-address-street",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "street_name",
                      attrs: {
                        id: "driver-address-street",
                        disabled: !_vm.addressName.editable || _vm.searching,
                        type: _vm.addressName.type,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel("street_name", $event)
                        },
                      },
                      model: {
                        value: _vm.address.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.address, "name", $$v)
                        },
                        expression: "address.name",
                      },
                    }),
                  ],
                  1
                ),
                _c("mask-input", {
                  ref: "street_number",
                  attrs: {
                    "mask-label": _vm.$t(_vm.addressNumber.title),
                    "parent-class": "col-md-1 col-sm-12 mask-style",
                    disabled:
                      !_vm.addressNumber.editable ||
                      _vm.searching ||
                      _vm.hasNoAddressNumberCheck ||
                      _vm.disableAddressNumber,
                    mask: _vm.addressNumber.mask,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.updateAddressModel("street_number", $event)
                    },
                  },
                  model: {
                    value: _vm.address.number,
                    callback: function ($$v) {
                      _vm.$set(_vm.address, "number", $$v)
                    },
                    expression: "address.number",
                  },
                }),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-2 col-sm-12",
                    staticStyle: { color: "white" },
                    attrs: {
                      id: "fg-driver-address-no-number",
                      "label-for": "driver-address-no-number",
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [_vm._v("   ")]),
                    _c(
                      "b-form-checkbox",
                      {
                        ref: "street_no_number",
                        staticClass: "mt-1",
                        attrs: {
                          disabled:
                            !_vm.addressNoNumber.editable ||
                            _vm.searching ||
                            _vm.hasAddressNumber,
                        },
                        on: {
                          change: _vm.checkNoNumber,
                          input: function ($event) {
                            return _vm.updateAddressModel(
                              "street_no_number",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.hasNoAddressNumberCheck,
                          callback: function ($$v) {
                            _vm.hasNoAddressNumberCheck = $$v
                          },
                          expression: "hasNoAddressNumberCheck",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.addressNoNumber.title) + " ")]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "b-form-row",
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-2 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-street-details",
                      label: _vm.addressComplement.title,
                      "label-for": "driver-address-street-details",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "street_details",
                      attrs: {
                        id: "driver-address-street-details",
                        disabled:
                          !_vm.addressComplement.editable || _vm.searching,
                        type: _vm.addressComplement.type,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel(
                            "street_details",
                            $event
                          )
                        },
                      },
                      model: {
                        value: _vm.address.complement,
                        callback: function ($$v) {
                          _vm.$set(_vm.address, "complement", $$v)
                        },
                        expression: "address.complement",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-3 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-neighborhood",
                      label: _vm.addressNeighborhood.title,
                      "label-for": "driver-address-neighborhood",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "neighborhood",
                      attrs: {
                        id: "driver-address-neighborhood",
                        disabled:
                          !_vm.addressNeighborhood.editable || _vm.searching,
                        type: _vm.addressNeighborhood.type,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel("neighborhood", $event)
                        },
                      },
                      model: {
                        value: _vm.address.neighborhood,
                        callback: function ($$v) {
                          _vm.$set(_vm.address, "neighborhood", $$v)
                        },
                        expression: "address.neighborhood",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-4 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-city",
                      label: _vm.addressCity.title,
                      "label-for": "driver-address-city",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "city",
                      attrs: {
                        id: "driver-address-city",
                        disabled: !_vm.addressCity.editable || _vm.searching,
                        type: _vm.addressCity.text,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel("city", $event)
                        },
                      },
                      model: {
                        value: _vm.address.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.address, "city", $$v)
                        },
                        expression: "address.city",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-3 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-state",
                      label: _vm.addressState.title,
                      "label-for": "driver-address-state",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "state",
                      attrs: {
                        disabled: !_vm.addressState.editable || _vm.searching,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel("state", $event)
                        },
                      },
                      model: {
                        value: _vm.address.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.address, "state", $$v)
                        },
                        expression: "address.state",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.validAddress
              ? _c(
                  "p",
                  { style: { color: _vm.messageErroColor, fontSize: "14px" } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.requiredAddressFields.message) + " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      [
        _c(
          "b-form-row",
          [
            _c(
              "b-form-group",
              {
                staticClass: "col-md-4 col-sm-12 mt-1 mb-0",
                staticStyle: { color: "white" },
                attrs: {
                  id: "fg-driver-address-no-number",
                  "label-for": "driver-address-no-number",
                },
              },
              [
                _c("template", { slot: "label" }, [_vm._v("   ")]),
                _c(
                  "b-form-checkbox",
                  {
                    ref: "street_no_number",
                    staticClass: "mt-1",
                    attrs: {
                      disabled: !_vm.showGarageAddress || !_vm.hasAddress,
                    },
                    on: {
                      change: _vm.checkhasGarage,
                      input: function ($event) {
                        return _vm.updateAddressModel(
                          "street_no_number",
                          $event
                        )
                      },
                    },
                    model: {
                      value: _vm.hasGarage,
                      callback: function ($$v) {
                        _vm.hasGarage = $$v
                      },
                      expression: "hasGarage",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.googleInput.garageCheckTitle) + " ")]
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm.hasGarage ? _c("hr") : _vm._e(),
      ],
      1
    ),
    _vm.showGarageAddress && _vm.hasGarage
      ? _c(
          "div",
          { staticClass: "kovi-address__input mb-3" },
          [
            _c("h5", { staticClass: "mt-1 mb-4 ml-0" }, [
              _vm._v(" " + _vm._s(_vm.googleInput.garageBlockTitle) + " "),
            ]),
            _c("p", { staticClass: "kovi-address__input-title" }, [
              _vm._v(" " + _vm._s(_vm.googleInput.garageTitle) + " "),
            ]),
            _c("google-address-autocomplete", {
              attrs: {
                id: "kovi-address-autocomplete-garage",
                clear: false,
                color: _vm.googleInput.color,
                "border-width": _vm.googleInput.borderWidth,
                "border-color": _vm.googleInput.borderColor,
                "border-radius": _vm.googleInput.borderRadius,
                "text-align": _vm.googleInput.textAlign,
                complete: _vm.googleInput.complete,
                "padding-top": _vm.googleInput.paddingTop,
                "padding-left": _vm.googleInput.paddingLeft,
                "padding-right": _vm.googleInput.paddingRight,
                "padding-bottom": _vm.googleInput.paddingBottom,
                locale: _vm.googleInput.locale,
                placeholder: _vm.googleInput.placeholder,
              },
              on: {
                changed: function ($event) {
                  return _vm.changeAddress($event, "garage")
                },
                "selected-value": function ($event) {
                  return _vm.changeAddress($event, "garage")
                },
                focus: _vm.autocompleteFocus,
                blur: _vm.autocompleteBlur,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.showGarageAddress && _vm.hasGarageAddress && _vm.hasGarage
      ? _c(
          "div",
          [
            _c(
              "b-form-row",
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-2 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-country",
                      label: _vm.garageAddressCountry.title,
                      "label-for": "driver-address-country",
                    },
                  },
                  [
                    _c("b-form-select", {
                      ref: "country",
                      attrs: {
                        disabled:
                          !_vm.garageAddressCountry.editable || _vm.searching,
                        value: _vm.garageAddressCountry.value,
                        options: _vm.garageAddressCountry.options,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.updateAddressModel("country", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("mask-input", {
                  ref: "postal_code",
                  attrs: {
                    "mask-label": _vm.garageAddressPostalCode.title,
                    "parent-class": "col-md-2 col-sm-12 mask-style",
                    disabled:
                      !_vm.garageAddressPostalCode.editable || _vm.searching,
                    mask: _vm.garageAddressPostalCode.mask,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.updateAddressModel("postal_code", $event)
                    },
                  },
                  model: {
                    value: _vm.gAddress.postalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.gAddress, "postalCode", $$v)
                    },
                    expression: "gAddress.postalCode",
                  },
                }),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-5 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-street",
                      label: _vm.garageAddressName.title,
                      "label-for": "driver-address-street",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "street_name",
                      attrs: {
                        id: "driver-address-street",
                        disabled:
                          !_vm.garageAddressName.editable || _vm.searching,
                        type: _vm.garageAddressName.type,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel("street_name", $event)
                        },
                      },
                      model: {
                        value: _vm.gAddress.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.gAddress, "name", $$v)
                        },
                        expression: "gAddress.name",
                      },
                    }),
                  ],
                  1
                ),
                _c("mask-input", {
                  ref: "street_number",
                  attrs: {
                    "mask-label": _vm.$t(_vm.garageAddressNumber.title),
                    "parent-class": "col-md-1 col-sm-12 mask-style",
                    disabled:
                      !_vm.garageAddressNumber.editable || _vm.searching,
                    mask: _vm.garageAddressNumber.mask,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.updateAddressModel("street_number", $event)
                    },
                  },
                  model: {
                    value: _vm.gAddress.number,
                    callback: function ($$v) {
                      _vm.$set(_vm.gAddress, "number", $$v)
                    },
                    expression: "gAddress.number",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-form-row",
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-2 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-street-details",
                      label: _vm.garageAddressComplement.title,
                      "label-for": "driver-address-street-details",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "street_details",
                      attrs: {
                        id: "driver-address-street-details",
                        disabled:
                          !_vm.garageAddressComplement.editable ||
                          _vm.searching,
                        type: _vm.garageAddressComplement.type,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel(
                            "street_details",
                            $event
                          )
                        },
                      },
                      model: {
                        value: _vm.gAddress.complement,
                        callback: function ($$v) {
                          _vm.$set(_vm.gAddress, "complement", $$v)
                        },
                        expression: "gAddress.complement",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-3 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-neighborhood",
                      label: _vm.garageAddressNeighborhood.title,
                      "label-for": "driver-address-neighborhood",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "neighborhood",
                      attrs: {
                        id: "driver-address-neighborhood",
                        disabled:
                          !_vm.garageAddressNeighborhood.editable ||
                          _vm.searching,
                        type: _vm.garageAddressNeighborhood.type,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel("neighborhood", $event)
                        },
                      },
                      model: {
                        value: _vm.gAddress.neighborhood,
                        callback: function ($$v) {
                          _vm.$set(_vm.gAddress, "neighborhood", $$v)
                        },
                        expression: "gAddress.neighborhood",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-4 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-city",
                      label: _vm.garageAddressCity.title,
                      "label-for": "driver-address-city",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "city",
                      attrs: {
                        id: "driver-address-city",
                        disabled:
                          !_vm.garageAddressCity.editable || _vm.searching,
                        type: _vm.garageAddressCity.text,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel("city", $event)
                        },
                      },
                      model: {
                        value: _vm.gAddress.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.gAddress, "city", $$v)
                        },
                        expression: "gAddress.city",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "col-md-3 col-sm-12",
                    attrs: {
                      id: "fg-driver-address-state",
                      label: _vm.garageAddressState.title,
                      "label-for": "driver-address-state",
                    },
                  },
                  [
                    _c("b-form-input", {
                      ref: "state",
                      attrs: {
                        disabled:
                          !_vm.garageAddressState.editable || _vm.searching,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateAddressModel("state", $event)
                        },
                      },
                      model: {
                        value: _vm.gAddress.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.gAddress, "state", $$v)
                        },
                        expression: "gAddress.state",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.validGarageAddress
              ? _c(
                  "p",
                  { style: { color: _vm.messageErroColor, fontSize: "14px" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.requiredGarageAddressFields.message) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }