import i18n from '@translate/i18n';

export function driverErrors(errorMessage) {
    let errorMsg = '';

    switch (errorMessage && errorMessage.split('GraphQL error: ')[1]) {
        case 'PHONE_NUMBER_DUPLICATED':
            errorMsg = i18n.t('drivers.texts.phoneNumberDuplicated')
            break
        case 'CPF_DUPLICATED':
            errorMsg = i18n.t('drivers.texts.cpfDuplicated')
            break
        case 'CPF_REQUIRED':
        case 'CPF não informado':
          errorMsg = i18n.t('drivers.texts.cpfRequired')
            break
        case 'CPF_INVALID':
          errorMsg = i18n.t('drivers.texts.cpfInvalid')
          break
        case 'CNH já existe':
          errorMsg = i18n.t('drivers.texts.licenceNumberDuplicated')
          break
        case 'LICENSE_NOT_ALLOWED_UPDATE':
        case 'Não é permitido alterar a CNH nesse step':
          errorMsg = i18n.t('drivers.texts.licenceNumberNotAllowedUpdate')
          break
        case 'LICENSE_INVALID':
        case 'CNH inválida':
          errorMsg = i18n.t('drivers.texts.licenceNumberInvalid')
          break
        case 'ADDRESS_INVALID':
        case 'Endereço inválido':
          errorMsg = i18n.t('drivers.texts.addressInvalid')
          break
        default:
          errorMsg = i18n.t('drivers.texts.errorSave')
          break
    }

    return errorMsg;
}
