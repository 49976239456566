<template>
  <div class="kovi-address">
    <div class="kovi-address__input mb-3">
      <p class="kovi-address__input-title">
        {{ googleInput.title }}
      </p>
      <google-address-autocomplete
        id="kovi-address-autocomplete-address"
        :clear="false"
        :color="googleInput.color"
        :border-width="googleInput.borderWidth"
        :border-color="googleInput.borderColor"
        :border-radius="googleInput.borderRadius"
        :text-align="googleInput.textAlign"
        :complete="googleInput.complete"
        :padding-top="googleInput.paddingTop"
        :padding-left="googleInput.paddingLeft"
        :padding-right="googleInput.paddingRight"
        :padding-bottom="googleInput.paddingBottom"
        :locale="googleInput.locale"
        :placeholder="googleInput.placeholder"
        @changed="changeAddress($event, 'address')"
        @selected-value="changeAddress($event, 'address')"
        @focus="autocompleteFocus"
        @blur="autocompleteBlur"
      />
    </div>

    <div v-if="hasAddress">
      <b-form-row>
        <b-form-group
          id="fg-driver-address-country"
          :label="addressCountry.title"
          label-for="driver-address-country"
          class="col-md-2 col-sm-12"
        >
          <b-form-select
            ref="country"
            :disabled="!addressCountry.editable || searching"
            :value="addressCountry.value"
            :options="addressCountry.options"
            @change="updateAddressModel('country', $event)"
          />
        </b-form-group>

        <mask-input
          ref="postal_code"
          v-model.lazy="address.postalCode"
          :mask-label="addressPostalCode.title"
          parent-class="col-md-2 col-sm-12 mask-style"
          :disabled="!addressPostalCode.editable || searching"
          :mask="addressPostalCode.mask"
          @input="updateAddressModel('postal_code', $event)"
        />

        <b-form-group
          id="fg-driver-address-street"
          :label="addressName.title"
          label-for="driver-address-street"
          class="col-md-5 col-sm-12"
        >
          <b-form-input
            id="driver-address-street"
            ref="street_name"
            v-model="address.name"
            :disabled="!addressName.editable || searching"
            :type="addressName.type"
            @input="updateAddressModel('street_name', $event)"
          />
        </b-form-group>

        <mask-input
          ref="street_number"
          v-model.lazy="address.number"
          :mask-label="$t(addressNumber.title)"
          parent-class="col-md-1 col-sm-12 mask-style"
          :disabled="!addressNumber.editable || searching || hasNoAddressNumberCheck || disableAddressNumber"
          :mask="addressNumber.mask"
          @input="updateAddressModel('street_number', $event)"
        />

        <b-form-group
          id="fg-driver-address-no-number"
          style="color: white"
          label-for="driver-address-no-number"
          class="col-md-2 col-sm-12"
        >
          <template slot="label">
            &nbsp;
          </template>
          <b-form-checkbox
            ref="street_no_number"
            v-model="hasNoAddressNumberCheck"
            :disabled="!addressNoNumber.editable || searching || hasAddressNumber"
            class="mt-1"
            @change="checkNoNumber"
            @input="updateAddressModel('street_no_number', $event)"
          >
            {{ addressNoNumber.title }}
          </b-form-checkbox>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group
          id="fg-driver-address-street-details"
          :label="addressComplement.title"
          label-for="driver-address-street-details"
          class="col-md-2 col-sm-12"
        >
          <b-form-input
            id="driver-address-street-details"
            ref="street_details"
            v-model="address.complement"
            :disabled="!addressComplement.editable || searching"
            :type="addressComplement.type"
            @input="updateAddressModel('street_details', $event)"
          />
        </b-form-group>
        <b-form-group
          id="fg-driver-address-neighborhood"
          :label="addressNeighborhood.title"
          label-for="driver-address-neighborhood"
          class="col-md-3 col-sm-12"
        >
          <b-form-input
            id="driver-address-neighborhood"
            ref="neighborhood"
            v-model="address.neighborhood"
            :disabled="!addressNeighborhood.editable || searching"
            :type="addressNeighborhood.type"
            @input="updateAddressModel('neighborhood', $event)"
          />
        </b-form-group>
        <b-form-group
          id="fg-driver-address-city"
          :label="addressCity.title"
          label-for="driver-address-city"
          class="col-md-4 col-sm-12"
        >
          <b-form-input
            id="driver-address-city"
            ref="city"
            v-model="address.city"
            :disabled="!addressCity.editable || searching"
            :type="addressCity.text"
            @input="updateAddressModel('city', $event)"
          />
        </b-form-group>
        <b-form-group
          id="fg-driver-address-state"
          :label="addressState.title"
          label-for="driver-address-state"
          class="col-md-3 col-sm-12"
        >
          <b-form-input
            ref="state"
            v-model="address.state"
            :disabled="!addressState.editable || searching"
            @input="updateAddressModel('state', $event)"
          />
        </b-form-group>
      </b-form-row>

      <p
        v-if="!validAddress"
        :style="{ color: messageErroColor, fontSize: '14px' }"
      >
        {{ requiredAddressFields.message }}
      </p>
    </div>

    <div>
      <b-form-row>
        <b-form-group
          id="fg-driver-address-no-number"
          style="color: white"
          label-for="driver-address-no-number"
          class="col-md-4 col-sm-12 mt-1 mb-0"
        >
          <template slot="label">
            &nbsp;
          </template>
          <b-form-checkbox
            ref="street_no_number"
            v-model="hasGarage"
            :disabled="!showGarageAddress || !hasAddress"
            class="mt-1"
            @change="checkhasGarage"
            @input="updateAddressModel('street_no_number', $event)"
          >
            {{ googleInput.garageCheckTitle }}
          </b-form-checkbox>
        </b-form-group>
      </b-form-row>

      <hr v-if="hasGarage">
    </div>

    <div v-if="showGarageAddress && hasGarage" class="kovi-address__input mb-3">
      <h5 class="mt-1 mb-4 ml-0">
        {{ googleInput.garageBlockTitle }}
      </h5>
      <p class="kovi-address__input-title">
        {{ googleInput.garageTitle }}
      </p>
      <google-address-autocomplete
        id="kovi-address-autocomplete-garage"
        :clear="false"
        :color="googleInput.color"
        :border-width="googleInput.borderWidth"
        :border-color="googleInput.borderColor"
        :border-radius="googleInput.borderRadius"
        :text-align="googleInput.textAlign"
        :complete="googleInput.complete"
        :padding-top="googleInput.paddingTop"
        :padding-left="googleInput.paddingLeft"
        :padding-right="googleInput.paddingRight"
        :padding-bottom="googleInput.paddingBottom"
        :locale="googleInput.locale"
        :placeholder="googleInput.placeholder"
        @changed="changeAddress($event, 'garage')"
        @selected-value="changeAddress($event, 'garage')"
        @focus="autocompleteFocus"
        @blur="autocompleteBlur"
      />
    </div>

    <div v-if="showGarageAddress && hasGarageAddress && hasGarage">
      <b-form-row>
        <b-form-group
          id="fg-driver-address-country"
          :label="garageAddressCountry.title"
          label-for="driver-address-country"
          class="col-md-2 col-sm-12"
        >
          <b-form-select
            ref="country"
            :disabled="!garageAddressCountry.editable || searching"
            :value="garageAddressCountry.value"
            :options="garageAddressCountry.options"
            @change="updateAddressModel('country', $event)"
          />
        </b-form-group>

        <mask-input
          ref="postal_code"
          v-model.lazy="gAddress.postalCode"
          :mask-label="garageAddressPostalCode.title"
          parent-class="col-md-2 col-sm-12 mask-style"
          :disabled="!garageAddressPostalCode.editable || searching"
          :mask="garageAddressPostalCode.mask"
          @input="updateAddressModel('postal_code', $event)"
        />

        <b-form-group
          id="fg-driver-address-street"
          :label="garageAddressName.title"
          label-for="driver-address-street"
          class="col-md-5 col-sm-12"
        >
          <b-form-input
            id="driver-address-street"
            ref="street_name"
            v-model="gAddress.name"
            :disabled="!garageAddressName.editable || searching"
            :type="garageAddressName.type"
            @input="updateAddressModel('street_name', $event)"
          />
        </b-form-group>

        <mask-input
          ref="street_number"
          v-model.lazy="gAddress.number"
          :mask-label="$t(garageAddressNumber.title)"
          parent-class="col-md-1 col-sm-12 mask-style"
          :disabled="!garageAddressNumber.editable || searching"
          :mask="garageAddressNumber.mask"
          @input="updateAddressModel('street_number', $event)"
        />
      </b-form-row>

      <b-form-row>
        <b-form-group
          id="fg-driver-address-street-details"
          :label="garageAddressComplement.title"
          label-for="driver-address-street-details"
          class="col-md-2 col-sm-12"
        >
          <b-form-input
            id="driver-address-street-details"
            ref="street_details"
            v-model="gAddress.complement"
            :disabled="!garageAddressComplement.editable || searching"
            :type="garageAddressComplement.type"
            @input="updateAddressModel('street_details', $event)"
          />
        </b-form-group>
        <b-form-group
          id="fg-driver-address-neighborhood"
          :label="garageAddressNeighborhood.title"
          label-for="driver-address-neighborhood"
          class="col-md-3 col-sm-12"
        >
          <b-form-input
            id="driver-address-neighborhood"
            ref="neighborhood"
            v-model="gAddress.neighborhood"
            :disabled="!garageAddressNeighborhood.editable || searching"
            :type="garageAddressNeighborhood.type"
            @input="updateAddressModel('neighborhood', $event)"
          />
        </b-form-group>
        <b-form-group
          id="fg-driver-address-city"
          :label="garageAddressCity.title"
          label-for="driver-address-city"
          class="col-md-4 col-sm-12"
        >
          <b-form-input
            id="driver-address-city"
            ref="city"
            v-model="gAddress.city"
            :disabled="!garageAddressCity.editable || searching"
            :type="garageAddressCity.text"
            @input="updateAddressModel('city', $event)"
          />
        </b-form-group>
        <b-form-group
          id="fg-driver-address-state"
          :label="garageAddressState.title"
          label-for="driver-address-state"
          class="col-md-3 col-sm-12"
        >
          <b-form-input
            ref="state"
            v-model="gAddress.state"
            :disabled="!garageAddressState.editable || searching"
            @input="updateAddressModel('state', $event)"
          />
        </b-form-group>
      </b-form-row>

      <p
        v-if="!validGarageAddress"
        :style="{ color: messageErroColor, fontSize: '14px' }"
      >
        {{ requiredGarageAddressFields.message }}
      </p>
    </div>
  </div>
</template>

<script>
function validObjs (obj, validKeys) {
  let isValid = true
  const objKeys = Object.keys(obj)

  for (let objKey of objKeys) {
    if (!validKeys.includes(objKey)) {
      isValid = false
      break
    }
  }

  return isValid
}

export default {
  name: 'KoviAddress',
  components: {
    GoogleAddressAutocomplete: () => import('@components/custom/address/GoogleAddressAutocomplete')
  },
  props: {
    // TODO incluir todas as props separadas aqui
    googleInput: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['color', 'borderWidth', 'borderColor', 'borderRadius', 'textAlign', 'complete', 'paddingTop', 'paddingLeft', 'paddingRight', 'paddingBottom', 'locale', 'placeholder', 'title', 'garageCheckTitle', 'garageBlockTitle', 'garageTitle']

        return validObjs(values, validKeys)
      }
    },
    addressName: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    addressNumber: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type', 'mask']
        return validObjs(values, validKeys)
      }
    },
    addressNoNumber: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'value']
        return validObjs(values, validKeys)
      }
    },
    addressNeighborhood: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    addressCity: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    addressState: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    addressCountry: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'options', 'type']
        return validObjs(values, validKeys)
      }
    },
    addressPostalCode: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type', 'mask', 'validLength']
        return validObjs(values, validKeys)
      }
    },
    addressComplement: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    addressLatLng: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    messageErroColor: {
      type: String,
      required: false,
      default: '#dc3545'
    },
    messageRequiredAddressFields: {
      type: String,
      required: false,
      default: 'São requeridos os campos '
    },
    messageRequiredGarageFields: {
      type: String,
      required: false,
      default: 'São requeridos os campos '
    },
    messageInvalidAddress: {
      type: String,
      required: false,
      default: 'Endereço inválido, pesquise novamente'
    },
    showGarageAddress: {
      type: Boolean,
      required: false,
      default: false,
      validator: (values) => {
        const validKeys = [true, false]
        return validObjs(values, validKeys)
      }
    },
    garageAddressName: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    garageAddressNumber: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type', 'mask']
        return validObjs(values, validKeys)
      }
    },
    garageAddressNeighborhood: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    garageAddressCity: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    garageAddressState: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    },
    garageAddressCountry: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'options', 'type']
        return validObjs(values, validKeys)
      }
    },
    garageAddressPostalCode: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type', 'mask', 'validLength']
        return validObjs(values, validKeys)
      }
    },
    garageAddressComplement: {
      type: Object,
      required: false,
      validator: (values) => {
        const validKeys = ['required', 'editable', 'title', 'placeholder', 'showPlaceholder', 'value', 'type']
        return validObjs(values, validKeys)
      }
    }
  },
  data () {
    return {
      validGoogleTypes: {
        addressNumberTypes: ['street_number'],
        addressNameTypes: ['route', 'bus_station', 'establishment', 'point_of_interest', 'transit_station'],
        addressNeighborhoodTypes: ['sublocality', 'sublocality_level_1', 'administrative_area_level_4'],
        addressCityTypes: ['administrative_area_level_2', 'locality'],
        addressStateTypes: ['administrative_area_level_1'],
        addressCountryTypes: ['country'],
        addressPostalCodeTypes: ['postal_code']
      },
      searching: false,
      hasAddressPostalCode: false,
      hasAddressNumber: false,
      hasNoAddressNumberCheck: false,
      disableAddressNumber: false,
      hasAddress: false,
      address: {
        name: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        country: '',
        complement: '',
        postalCode: '',
        latLng: ''
      },
      hasGarage: false,
      hasGaragePostalCode: false,
      hasGarageNumber: false,
      hasGarageAddress: false,
      gAddress: {
        name: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: '',
        postalCode: ''
      }
    }
  },
  computed: {
    validAddress () {
      return this.requiredAddressFields.status
    },
    validGarageAddress () {
      if (!this.hasGarage) return true

      return this.requiredGarageAddressFields.status
    },
    requiredAddressFields () {
      const { name, number, neighborhood, city, state, country, postalCode, complement, latLng } = this.address
      const requiredFields = []
      let status = false

      if (this.addressName.required && !name) requiredFields.push(this.addressName.title)
      if (this.addressNeighborhood.required && !neighborhood) requiredFields.push(this.addressNeighborhood.title)
      if (this.addressCity.required && !city) requiredFields.push(this.addressCity.title)
      if (this.addressState.required && !state) requiredFields.push(this.addressState.title)
      if (this.addressCountry.required && !country) requiredFields.push(this.addressCountry.title)
      if (this.addressPostalCode.required && (!postalCode || postalCode.length < this.addressPostalCode.validLength)) requiredFields.push(this.addressPostalCode.title)
      if (this.addressComplement.required && !complement) requiredFields.push(this.addressComplement.title)

      if ((this.addressNumber.required && (!number && !this.hasNoAddressNumberCheck))) requiredFields.push(this.addressNumber.title)

      if (!requiredFields.length) status = true

      let message = `${this.messageRequiredAddressFields} (${requiredFields.toString()})`

      if (!latLng) {
        status = false
        message = this.messageInvalidAddress
      }

      return {
        status,
        message
      }
    },
    requiredGarageAddressFields () {
      const { name, number, neighborhood, city, state, postalCode, complement } = this.gAddress
      const requiredFields = []

      if (this.garageAddressName.required && !name) requiredFields.push(this.garageAddressName.title)
      if (this.garageAddressNumber.required && !number) requiredFields.push(this.garageAddressNumber.title)
      if (this.garageAddressNeighborhood.required && !neighborhood) requiredFields.push(this.garageAddressNeighborhood.title)
      if (this.garageAddressCity.required && !city) requiredFields.push(this.garageAddressCity.title)
      if (this.garageAddressState.required && !state) requiredFields.push(this.garageAddressState.title)
      if (this.garageAddressPostalCode.required && (!postalCode || postalCode.length < this.garageAddressPostalCode.validLength)) requiredFields.push(this.garageAddressPostalCode.title)
      if (this.garageAddressComplement.required && !complement) requiredFields.push(this.garageAddressComplement.title)

      return {
        status: !requiredFields.length ? true : false,
        message: `${this.messageRequiredGarageFields} (${requiredFields.toString()})`
      }
    }
  },
  watch: {
    address: {
      deep: true,
      handler (value) {
        const { name, neighborhood, city, state } = value

        if (name || neighborhood || city || state) {
          this.hasAddress = true
        }

        this.hasAddressPostalCode = (value.postalCode && value.postalCode.length >= this.addressPostalCode.validLength) ? true : false

        if (value.number) {
          this.hasAddressNumber = true
          this.hasNoAddressNumberCheck = false
        } else {
          this.hasAddressNumber = false
        }

        this.sendAddress()
      }
    },
    gAddress: {
      deep: true,
      handler (value) {
        const { name, neighborhood, city, state } = value

        if (!name || !neighborhood || !city || !state) {
          this.hasGarageAddress = false
        } else {
          this.hasGarageAddress = true
        }

        this.hasGaragePostalCode = (value.postalCode && value.postalCode.length >= this.garageAddressPostalCode.validLength) ? true : false

        if (value.number) {
          this.hasAddressNumber = true
        } else {
          this.hasAddressNumber = false
        }

        this.sendAddress()
      }
    },
    hasNoAddressNumberCheck () {
      this.sendAddress()
    },
    hasGarage () {
      this.sendAddress()
    }
  },
  mounted() {
    this.address.country = this.addressCountry.value || 'BR'
    this.setValues()
  },
  methods: {
    setValues () {
      this.hasNoAddressNumberCheck = this.addressNoNumber.value

      this.address.name = this.addressName.value
      this.address.neighborhood = this.addressNeighborhood.value
      this.address.city = this.addressCity.value
      this.address.state = this.addressState.value
      this.address.complement = this.addressComplement.value
      this.address.latLng = this.addressLatLng.value

      this.address.number = this.addressNumber.value
      if (this.addressNumber.value) {
        this.hasAddressNumber = true
        this.disableAddressNumber = false
      }

      this.address.postalCode = this.addressPostalCode.value
      if(this.addressPostalCode.value) this.hasAddressPostalCode = true

      this.gAddress.name = this.garageAddressName.value
      this.gAddress.number = this.garageAddressNumber.value
      this.gAddress.neighborhood = this.garageAddressNeighborhood.value
      this.gAddress.city = this.garageAddressCity.value
      this.gAddress.state = this.garageAddressState.value
      this.gAddress.complement = this.garageAddressComplement.value
      this.gAddress.postalCode = this.garageAddressPostalCode.value

      const { name, number, neighborhood, city, state, complement, postalCode } = this.gAddress

      if (name || number || neighborhood || city || state || complement || postalCode) {
        this.hasGarage = true
        this.hasGarageAddress = true

        if (postalCode) this.hasGaragePostalCode = true
        if (number) this.hasGarageNumber = true
      }

    },
    autocompleteFocus () {},
    autocompleteBlur () {},
    updateAddressModel () {},
    checkNoNumber($checked) {
      if ($checked) {
        this.address.number = '';
        this.hasAddressNumber = false
        this.disableAddressNumber = true
      } else {
        this.hasAddressNumber = false
        this.disableAddressNumber = false
      }
    },
    checkhasGarage($checked) {
      this.hasGarage = $checked

      if (!$checked) this.clearGarageAddress()
    },
    clearGarageAddress () {
      this.hasGarage = false
      this.hasGaragePostalCode = false
      this.hasGarageNumber = false
      this.hasGarageAddress = false
      this.gAddress = {
        name: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: '',
        postalCode: ''
      }
    },
    changeAddress (event, type) {
      this.searching = true
      let newValue = event ? event : {}

      if (this.noEmptyObjs(newValue)) {
        const newAddress = newValue.address_components

        if (type === 'garage') {
          this.updateGarage(newValue, newAddress)
        } else {
          this.updateAddress(newValue, newAddress)
        }
      }

      this.searching = false
    },
    updateAddress (newValue, newAddress) {
      const { addressNumberTypes, addressNameTypes, addressNeighborhoodTypes, addressCityTypes, addressStateTypes, addressPostalCodeTypes } = this.validGoogleTypes
      this.address.number = ''
      this.hasAddressNumber = false
      this.disableAddressNumber = false
      this.hasNoAddressNumberCheck = false
      this.address.postalCode = ''
      this.hasAddressPostalCode = false

      const defaultEmptyObj = {
        long_name: '',
        short_name: '',
        types: []
      }

      let _ = this

      newAddress.forEach(item => {
        const itemType = item.types

        if (addressNumberTypes.some(type => itemType.includes(type))) {
          _.address.number = item.long_name
          _.hasAddressNumber = true
          _.disableAddressNumber = true
        }

        if (addressNameTypes.some(type => itemType.includes(type))) {
          let addressName = ''
          if (itemType.includes('transit_station')) {
            let addressName = defaultEmptyObj
            const newAddressName = _.removeAddressNumber(item.long_name)

            addressName.types = item.types
            addressName.long_name = newAddressName[0]
            addressName.short_name = newAddressName[0]
          } else {
            addressName = item
          }

          _.address.name = addressName.long_name
        }

        if (addressNeighborhoodTypes.some(type => itemType.includes(type))) _.address.neighborhood = item.long_name

        if (addressCityTypes.some(type => itemType.includes(type))) _.address.city = item.long_name

        if (addressStateTypes.some(type => itemType.includes(type))) _.address.state = item.short_name

        if (addressPostalCodeTypes.some(type => itemType.includes(type))) {
          _.hasAddressPostalCode = true
          _.address.postalCode = this.clearPostalCode(item.long_name)
        }

        _.address.latLng = `${newValue.geometry.location.lat()}, ${newValue.geometry.location.lng()}`
      })

      if (!this.address.number) {
        this.hasAddressNumber = false
        this.hasNoAddressNumberCheck = true
      }

      if (!this.address.postalCode) {
        this.hasAddressPostalCode = true
      }

      this.sendAddress()
    },
    updateGarage (newValue, newAddress) {
      const { addressNumberTypes, addressNameTypes, addressNeighborhoodTypes, addressCityTypes, addressStateTypes, addressPostalCodeTypes } = this.validGoogleTypes

      this.gAddress.number = ''
      this.hasGarageNumber = false
      this.gAddress.postalCode = ''
      this.hasGaragePostalCode = false

      const defaultEmptyObj = {
        long_name: '',
        short_name: '',
        types: []
      }

      let _ = this

      newAddress.forEach(item => {
        const itemType = item.types

        if (addressNumberTypes.some(type => itemType.includes(type))) {
          _.gAddress.number = item.long_name
          _.hasGarageNumber = true
        }

        if (addressNameTypes.some(type => itemType.includes(type))) {
          let addressName = ''
          if (itemType.includes('transit_station')) {
            let addressName = defaultEmptyObj
            const newAddressName = _.removeAddressNumber(item.long_name)

            addressName.types = item.types
            addressName.long_name = newAddressName[0]
            addressName.short_name = newAddressName[0]
          } else {
            addressName = item
          }

          _.gAddress.name = addressName.long_name
        }

        if (addressNeighborhoodTypes.some(type => itemType.includes(type))) _.gAddress.neighborhood = item.long_name

        if (addressCityTypes.some(type => itemType.includes(type))) _.gAddress.city = item.long_name

        if (addressStateTypes.some(type => itemType.includes(type))) _.gAddress.state = item.short_name

        if (addressPostalCodeTypes.some(type => itemType.includes(type))) {
          _.hasGaragePostalCode = true
          _.gAddress.postalCode = this.clearPostalCode(item.long_name)
        }
      })

      if (!this.gAddress.number) {
        this.hasGarageNumber = false
      }

      if (!this.address.postalCode) {
        this.hasAddressPostalCode = false
      }

      this.hasGarageAddress = true

      this.sendAddress()
    },
    clearPostalCode (value) {
      return value.replace('-', '').replace('.', '')
    },
    removeAddressNumber (address) {
      const addressArray = address.split(',')
      const numberGroup = addressArray[1].split(' - ')
      addressArray[1] = ` ${numberGroup[1]}`

      return addressArray
    },
    noEmptyObjs (obj) {
      if (Object.keys(obj).length === 0 && obj.constructor === Object) return false

      return true
    },
    sendAddress () {
      this.$emit('new-address', {
        'addressIsValid': this.validAddress,
        address: {
          ...this.address,
          no_number: this.hasNoAddressNumberCheck
        },
        hasGarage: this.hasGarageAddress,
        'garageIsValid': this.validGarageAddress,
        garage: {
          ...this.gAddress
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.kovi-address {
  .kovi-address__input {
    .kovi-address__input-title {
      margin-bottom: 10px;
    }
  }
}

/deep/ .mask-style .col-form-label {
  padding-bottom: 4px;
}
</style>
